import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from 'components/Layout'
import Container from 'components/Container'


export default function NewsLetterPage({ data: { site, allMdx } }) {
  return (
    <Layout site={site} noFooter={false}>
      <Container
        css={css`
          padding-bottom: 0;
        `}
      >

        <h1>Slides</h1>

        <a href="/slides/react_indy_hooks.pdf">09/26/2019 React Indy - Hooks: What's all the fuss?</a>
        
        <br />
        <br />
        <br />
        <br />

      </Container>
    </Layout>
  )
}


export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
  }
`
